/* mystical-style.css */

/* Estilos para el Header */
.app-header {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px; /* Espaciado interno */
    background-color: transparent; /* Fondo transparente o cambia según tu diseño */
    position: sticky; /* Fija el header en la parte superior al hacer scroll */
    top: 0;
    z-index: 1000; /* Asegura que el header esté por encima de otros elementos */
}

.arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 50px;          /* Set explicit width */
    height: 50px;         /* Set explicit height */
    background: rgba(255, 255, 255, 0.6);
    border: none;
    color: #F27272;
    font-size: 30px;      /* Arrow size */
    cursor: pointer;
    z-index: 2;
    border-radius: 50%;   /* Ensure it is a circle */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    display: flex;        /* To center the arrow inside */
    align-items: center;  /* Center vertically */
    justify-content: center; /* Center horizontally */
}
  
  .arrow-left {
    left: 10px;
  }
  
  .arrow-right {
    right: 10px;
  }
  
  .arrow:hover {
    background: #F27272; /* Change background color on hover */
    color: #ffffff;
  }

  
/* General styles */
body, html {
    font-family: 'Poppins', sans-serif;
    background-color: #10192B;
    color: #fff;
    margin: 0;
    padding: 0;
    position: relative;
    overflow-x: hidden;
}

/* Adding the subtle background blurs */
body::before, body::after {
    content: "";
    position: absolute;
    width: 800px;
    height: 800px;
    background: radial-gradient(circle, rgba(121,32,234,0.6), transparent 70%);
    top: -150px;
    left: -250px;
    z-index: -1;
}

body::after {
    background: radial-gradient(circle, rgba(58,85,242,0.5), transparent 70%);
    top: 300px;
    left: 600px;
    z-index: -1;
}

/* Ensure each card is positioned relatively to contain the absolute info card */
.card {
    position: relative; /* Make the card a positioned element */
}

/* Floating info card styles */
.info-card {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(35, 41, 78, 0.95); /* Dark semi-transparent background */
    color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2; /* Ensure it appears above other elements */
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

/* When the info card is active, make it visible */
.card .info-card {
    opacity: 1;
    visibility: visible;
}

/* Close button styles */
.close-info-card {
    position: absolute;
    top: 10px;
    right: 15px;
    background: none;
    border: none;
    color: #fff;
    font-size: 1.5em;
    cursor: pointer;
    outline: none;
}

/* Optional: Add hover effect to close button */
.close-info-card:hover {
    color: #F27272;
}

/* Ensure the info card text is readable */
.info-card p {
    font-size: 1em;
    text-align: center;
    padding: 0 10px;
}


.cards-section {
    text-align: center;
    margin: 150px 0;
}

.cards-title {
    
    font-size: 1.8em;
    color: #fff8d4;
}

.cards-description {
    font-size: 1.2em;
    color: #fff;
    margin-bottom: 30px;
}

/* Desktop grid for the cards */
.cards-grid {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.cards-grid .card {
    max-width: 300px;
    cursor: pointer;
}

.cards-grid img {
    width: 100%;
    border-radius: 10px;
}

/* Mobile carousel */
.cards-carousel {
    display: none;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.cards-carousel .carousel-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    transition: transform 0.5s ease-in-out;
}

.cards-carousel img {
    max-width: 65%;  /* Adjust the size to make it smaller */
    max-height: 80vh; /* Ensure the image does not exceed the height of the viewport */
    border-radius: 10px;
    margin: 0 auto;   /* Center the image */
}

/* Customize react-slick arrows */
.cards-carousel .slick-prev:before,
.cards-carousel .slick-next:before {
    color: #F27272; /* Arrow color */
    font-size: 30px; /* Arrow size */
}

/* Position arrows */
.cards-carousel .slick-prev,
.cards-carousel .slick-next {
    z-index: 1; /* Ensure arrows are above the slides */
}

/* Customize react-slick dots */
.cards-carousel .slick-dots li button:before {
    color: #F27272; /* Dot color */
}

.cards-carousel .slick-dots li.slick-active button:before {
    color: #fff8d4; /* Active dot color */
}

/* Cambio de las barras cuando el menú está abierto */
.contact-container.active .hamburger .hamburger-bar:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
}

.contact-container.active .hamburger .hamburger-bar:nth-child(2) {
    opacity: 0;
}

.contact-container.active .hamburger .hamburger-bar:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
}

/* Contenedor del Enlace de Contacto */
.contact-container {
    display: flex;
    justify-content: flex-end; /* Alinea el enlace de contacto a la derecha */
}

/* Estilos para el Enlace de Contacto */
.contact-link {
    font-size: 1em;
    color: #fff8d4; /* Color del texto #fff8d4 */
    text-decoration: none;
    padding: 8px 16px;
    border: 2px solid #fff8d4; /* Borde alrededor del enlace con el mismo color */
    border-radius: 5px; /* Bordes redondeados */
    transition: background-color 0.3s ease, color 0.3s ease;
}

/* Efecto Hover para el Enlace de Contacto */
.contact-link:hover {
    background-color: #fff8d4; /* Fondo al pasar el cursor */
    color: #333; /* Texto oscuro al pasar el cursor */
}

/* Download Button Styles */
.download-button {
    margin-top: 15px; /* Space above the button */
    padding: 10px 20px; /* Top/Bottom and Left/Right padding */
    background-color: #F27272; /* Button background color */
    color: #fff; /* Text color */
    border: none; /* Remove default border */
    border-radius: 5px; /* Rounded corners */
    font-size: 1em; /* Font size */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Hover Effects for Download Button */
.download-button:hover {
    background-color: #d65b5b; /* Darker shade on hover */
    transform: scale(1.05); /* Slightly enlarge the button */
}

/* Active/Pressed State for Download Button */
.download-button:active {
    background-color: #b84c4c; /* Even darker shade when pressed */
    transform: scale(1); /* Return to original size */
}

/* Error message styling */

.error-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }
  
  .error-message {
    background-color: #741212;
    border: 1px solid #ff9999;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
  }
  
  .error-message h2 {
    color: #cc0000;
    margin-bottom: 10px;
  }
  
  .error-message p {
    color: #660000;
  }
  
  .back-link {
    margin-top: 20px;
    display: inline-block;
    padding: 10px 20px;
    background-color: #cc0000;
    color: #ffffff;
    text-decoration: none;
    border-radius: 4px;
  }
  
  .back-link:hover {
    background-color: #b30000;
  }
  
/* mystical-style.css */

.fallback-link-container {
    margin-top: 10px;
    text-align: center;
  }

.fallback-link-button {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #F27272; /* Same as your button color */
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1.2em;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .fallback-link-button:hover {
    background-color: #D86060; /* Darker shade on hover */
    transform: scale(1.05); /* Slightly enlarge the button */
  }
  

/* Form styling */
form {
    display: flex;
    flex-direction: column;
    background-color: #23294E;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
    margin: 20px auto;
    width: 90%;
    max-width: 600px;
    opacity: 1;
    transition: opacity 0.6s ease-out;
    margin-bottom: 50px;
}

/* Botón de Hamburguesa */
.hamburger {
    display: none; /* Oculto por defecto, se mostrará en móviles */
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 21px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 1001; /* Asegura que esté por encima del menú */
}

.hamburger:focus {
    outline: none; /* Elimina el borde de enfoque predeterminado */
}

.hamburger-bar {
    width: 100%;
    height: 3px;
    background-color: #fff8d4; /* Color de las barras */
    border-radius: 2px;
    transition: all 0.3s ease;
}

/* Estilos para la Sección Info Tarot */
.info-tarot {
    text-align: center;
    margin: 50px 0;
}

.info-tarot h2 {
    font-size: 2em;
    color: #fff8d4;
    margin-bottom: 20px;
}

.info-tarot p {
    font-size: 1.2em;
    color: #fff;
    margin-bottom: 15px;
}

.info-tarot ul {
    list-style-type: disc;
    list-style-position: inside;
    font-size: 1em;
    color: #fff;
    max-width: 600px;
    margin: 0 auto;
    text-align: left; /* Alinea la lista a la izquierda para mejor legibilidad */
}

.info-tarot ul li {
    margin-bottom: 10px;
}

input, textarea {
    background-color: #2A315B;
    border: none;
    color: #fff;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
}

input::placeholder, textarea::placeholder {
    color: #A6A6A6;
}

.intro-tarot {
    max-width: 800px; /* Limita el ancho máximo para evitar que el texto se extienda demasiado */
    margin: 0 auto; /* Centra el div horizontalmente dentro de su contenedor */
    padding: 2px; /* Añade espacio interno alrededor del texto */
    line-height: 1.6; /* Mejora la legibilidad aumentando la altura de línea */
    font-size: 1em; /* Tamaño de fuente cómodo para lectura */
    text-align: center; /* Justifica el texto para una apariencia limpia */
}

button {
    background-color: #F27272;
    border: none;
    color: #FFFFFF;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #D86060;
}

/* H1 fade out */
h1 {
    font-size: 4em;
    color: #fff8d4;
    font-family: 'Playfair Display', serif;
    font-weight: 800;
    text-align: center; 
    opacity: 1;
    transition: opacity 0.6s ease-out;
}

a {
    color: #F27272;
}

/* Search results styling */
.search-results {
    background-color: #23294E;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
    border: 1px solid #F27272;
}

.search-result-item {
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 8px;
    background-color: #2A315B;
    box-shadow: 0 0 10px rgba(242, 114, 114, 0.3);
}

.search-result-title {
    font-size: 18px;
    color: #F27272;
    font-family: 'Playfair Display', serif;
    font-weight: 800;
    margin-bottom: 8px;
}

.search-result-content {
    font-size: 16px;
    color: #fff;
    margin-bottom: 8px;
}

.search-result-link {
    font-size: 14px;
    color: #F27272;
    text-decoration: underline;
}

.search-result-link:hover {
    color: #D86060;
}

/* Loading animation and icon */
.loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.loading-animation {
    text-align: center;
}

/* Estilos para la Sección Lucky Cards */
.lucky-cards {
    text-align: center;
    margin: 50px 0;
}

.lucky-cards h2 {
    font-size: 2em;
    color: #fff8d4;
    margin-bottom: 20px;
}

.lucky-cards p {
    font-size: 1.2em;
    color: #fff;
    margin-bottom: 20px;
}

.lucky-cards-image {
    width: 100%;
    max-width: 900px; /* Ajusta el tamaño según prefieras */
}


.icon {
    font-size: 4em;
    color: #fff8d4;
    animation: pulse 1.5s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

.loading-text h2 {
    font-size: 2.5em;
    color: #fff8d4;
}

.loading-text p {
    font-size: 1.2em;
    color: #fff8d4;
}

/* Contenedor del Logo */
.logo-container {
    flex-grow: 1; /* El logo ocupará todo el espacio disponible a la izquierda */
}

.logo {
    height: 100px; /* Aumenta la altura del logo */
    width: auto; /* El ancho se ajustará proporcionalmente */
}


/* Privacy text */
.privacy-text {
    font-size: 12px;
    color: #A6A6A6;
    text-align: center;
}

/* Result Page Styling */
.result-page {
    column-count: 2;
    column-gap: 20px;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}


/* Tarot Card and Description */
.tarot-card-row {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    margin-bottom: 40px;
    align-items: center; /* Ensures vertical centering of all columns in the row */
}

.tarot-card-column {
    flex: 1;
    text-align: center;
}

.tarot-description-column {
    flex: 2;
    color: #fff;
    display: flex;                /* Enables flexbox on this column */
    flex-direction: column;        /* Makes sure the content flows vertically */
    justify-content: center;       /* Vertically centers the content */
    gap: 10px;                     /* Adds some spacing between elements */
}

/* Modal styles */
.image-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
}

.close-modal {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #fff;
    font-size: 40px;
    font-weight: bold;
    cursor: pointer;
    z-index: 1001;
}

.modal-content {
    max-width: 80%;
    max-height: 80%;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
}

.result-image {
    width: 100%;
    max-width: 100%;
    height: auto;
    border-radius: 25px;
    margin: 0 auto;
    cursor: pointer;
}


h3 {
    font-size: 1.8em;
    color: #fff8d4;
}

.divider {
    height: 2px;
    background-color: #fff8d4;
    margin: 10px 0;
}

/* Lucky Numbers, Aura Color, and Initial Letter */
.tarot-info-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    
}

.tarot-info-column {
    flex: 1;
    text-align: center;
}

h4 {
    font-size: 1.5em;
    color: #fff8d4;
}

.lucky-numbers {
    font-family: 'Playfair Display', serif;
    font-size: 1.5em;
}

.aura-color-circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid #fff;
    margin: 0 auto;
}

.aura-color-name {
    font-family: 'Playfair Display', serif;
    margin-top: 10px;
}

.initial-letter {
    font-family: 'Playfair Display', serif;
    font-size: 4em;
    margin-top: 10px;
}

/* Mantra Row */
.mantra-row {
    text-align: center;
    margin-top: 150px;
    margin-bottom: 50px;
}

.mantra {
    font-family: 'Playfair Display', serif;
    font-size: 2em;
    color: #fff;
}

/* General page layout */
.tarot-header-section {
    width: 100%;
    max-width: 1200px;
    margin: 20px auto;
    padding: 0 20px;
}

.tarot-description-p{
    font-size: 1.2em;
}

/* Estilos para la Sección de Seguridad y Confianza */
.trust-section {
    text-align: center;
    margin: 50px 0;
    padding: 50px;
}

.trust-section h2 {
    font-size: 1em;
    color: #a6a6a6;
    margin-bottom: 20px;
}

.trust-section p {
    font-size: .8em;
    color: #a6a6a6;
    margin-bottom: 20px;
}

.trust-image {
    width: 100%;
    max-width: 200px; /* Ajusta el tamaño según prefieras */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.result-card p, .result-card a, .result-card li {
    word-wrap: break-word;
    word-break: break-word;
}


/* Card layout for results */
.result-card {
    background-color: #23294E;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
    position: relative;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
}


.result-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.35);
}

.result-card h3 {
    font-size: 1.8em;
    color: #fff8d4;
    margin-bottom: 10px;
}

.result-card p, .result-card li, .result-card a, .result-card table, .result-card th, .result-card td {
    color: #fff;
    font-size: 1em;
}

.result-card a {
    color: #F27272;
    text-decoration: underline;
}

.result-card a:hover {
    color: #D86060;
}

/* Table Styling */
table {
    width: 100%;
    border-collapse: collapse;
}

.table-container {
    overflow-x: auto;
}

table {
    width: 100%;
    min-width: 600px; /* Adjust as necessary */
    /* Existing table styles */
}


th, td {
    border: 1px solid #F27272;
    padding: 10px;
    text-align: left;
    background-color: #2A315B;
}

th {
    background-color: #23294E;
    font-weight: bold;
}


/* Estilos para la Sección de Compartir */
.share-section {
    text-align: center;
}

.share-section p {
    font-size: 1em;
    color: #fff8d4;
    margin-bottom: 15px;
}

.share-buttons {
    display: flex;
    justify-content: center;
    gap: 10px; /* Reducido para botones más pequeños */
    flex-wrap: wrap;
}

.share-button {
    display: flex;
    align-items: center;
    padding: 8px 16px; /* Reducido para botones más pequeños */
    border: none;
    border-radius: 5px;
    color: #fff;
    text-decoration: none;
    font-size: 0.9em; /* Reducido para botones más pequeños */
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2); /* Sombra reducida */
}

.share-button:hover {
    transform: translateY(-2px); /* Movimiento reducido */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Sombra al hacer hover */
}

.share-icon {
    margin-right: 6px; /* Espacio reducido entre el ícono y el texto */
    font-size: 1em; /* Tamaño del ícono reducido */
    display: flex;
    align-items: center;
}

/* Colores específicos para cada plataforma */
.share-button.twitter {
    background-color: #1DA1F2; /* Color de Twitter */
}

.share-button.whatsapp {
    background-color: #25D366; /* Color de WhatsApp */
}

.share-button.linkedin {
    background-color: #0077B5; /* Color de LinkedIn */
}

.privacy-notice {
    margin-top: 50px;
    margin-bottom: 50px;
    font-size: 12px;
    color: #fff;
    text-align: center;
}


/* Hide arrows by default on desktop version */
@media (min-width: 769px) {
    .arrow {
      display: none;
    }
  }


/* Responsive layout for mobile */
@media (max-width: 768px) {
    h1 {
        font-size: 3em; 
    }

    .app-header {
        flex-direction: column; /* Apila los elementos verticalmente */
        align-items: flex-start; /* Alinea los elementos al inicio */
        padding: 10px 20px; /* Ajusta el padding para móviles */
    }

    .arrow {
        display: block;
      }

    .cards-grid {
        display: none;
    }
  
    .cards-carousel {
        display: block;
    }

    .cards-carousel .carousel-slide {
        padding: 0px;
    }
    
    .info-card {
        padding: 45px;
    }

    .info-card p {
        font-size: 0.9em;
    }

    .close-info-card {
        font-size: 1.2em;
    }

    .contact-container {
        position: absolute;
        right: 30px;
        flex-direction: column;
        align-items: center;
        border-radius: 8px;
        padding: 10px 0;
    }

    /* Mostrar el menú cuando está activo */
    .contact-container.active {
        display: flex;
    }

    /* Enlaces dentro del menú */
    .contact-link {
        margin: 10px 0;
        width: 100%;
        text-align: center;
        border: none; /* Quita el borde en el menú desplegable */
        background-color: transparent; /* Fondo transparente */
        color: #fff8d4; /* Color del texto */
        padding: 10px 0;
        font-size: 1em; /* Tamaño de fuente más grande */
    }

    .contact-link:hover {
        background-color: transparent; /* Mantiene el fondo transparente */
        color: #F27272; /* Cambia el color del texto al pasar el cursor */
    }


    .download-button {
        width: 100%; /* Full width on smaller screens */
        padding: 12px 0; /* Increased padding for touch targets */
        font-size: 1.1em; /* Slightly larger text */
    }
    
    form {
        width: 80%;  
        margin: 0 auto;
    }

    /* Mostrar el botón de hamburguesa en móviles */
    .hamburger {
        display: flex;
    }


    .icon {
        font-size: 3em;
    }

    .intro-tarot {
        max-width: 90%; /* Reduce el ancho máximo en dispositivos móviles */
        padding: 15px; /* Ajusta el padding para pantallas pequeñas */
        font-size: 0.9em; /* Tamaño de fuente ligeramente menor para móviles */
        
        
        box-shadow: none; /* Opcional: elimina la sombra en móviles para simplificar el diseño */
    }

    .loading-text h2 {
        font-size: 2em;
    }

    .logo {
        height: 90px; /* Ajusta la altura del logo en móviles */
        margin-bottom: 10px; /* Espacio entre el logo y el enlace */
    }


    .result-image {
        margin: 0 auto;
    }

    .result-page {
        column-count: 1;
        column-gap: 15px;
    }

    .share-buttons {
        flex-wrap: nowrap; /* Evita que los botones se envuelvan a la siguiente línea */
        overflow-x: auto; /* Permite el desplazamiento horizontal si es necesario */
    }

    .share-button {
        flex: 1 1 auto; /* Permite que los botones crezcan y se contraigan según el espacio disponible */
        max-width: 150px; /* Establece un ancho máximo para evitar que los botones sean demasiado grandes */
        justify-content: center;
        padding: 8px 12px; /* Ajuste de padding para móviles */
        font-size: 0.85em; /* Ajuste de tamaño de fuente para móviles */
    }

    .share-icon {
        margin-right: 4px; /* Espacio aún más reducido entre el ícono y el texto */
        font-size: 0.9em; /* Tamaño del ícono reducido para móviles */
    }

    .share-section p {
        font-size: 1em; /* Ajuste del tamaño de fuente del título para móviles */
    }

    .tarot-card-row, .tarot-info-row {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .tarot-description-column, .tarot-info-column {
        width: 100%;
    }

    .tarot-header-section {
        width: 90%;
    }

    .result-card h3 {
        font-size: 1.5em;
    }

    /* Adjust react-slick arrows for mobile */
    .cards-carousel .slick-prev:before,
    .cards-carousel .slick-next:before {
        color: #F27272; /* Arrow color */
        font-size: 25px; /* Arrow size */
    }

    /* Adjust react-slick dots for mobile */
    .cards-carousel .slick-dots li button:before {
        color: #F27272; /* Dot color */
    }

    .cards-carousel .slick-dots li.slick-active button:before {
        color: #fff8d4; /* Active dot color */
    }
}

/* Additional styles for background gradients */
.background-2 { 
    background: #092756;
    background: -moz-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%),-moz-linear-gradient(top, rgba(57,173,219,.25) 0%, rgba(42,60,87,.4) 100%), -moz-linear-gradient(-45deg, #670d10 0%, #092756 100%);
    background: -webkit-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%), -webkit-linear-gradient(top, rgba(57,173,219,.25) 0%,rgba(42,60,87,.4) 100%), -webkit-linear-gradient(-45deg, #670d10 0%,#092756 100%);
    background: -o-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%), -o-linear-gradient(top, rgba(57,173,219,.25) 0%,rgba(42,60,87,.4) 100%), -o-linear-gradient(-45deg, #670d10 0%,#092756 100%);
    background: -ms-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%), -ms-linear-gradient(top, rgba(57,173,219,.25) 0%,rgba(42,60,87,.4) 100%), -ms-linear-gradient(-45deg, #670d10 0%,#092756 100%);
    background: -webkit-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%), linear-gradient(to bottom, rgba(57,173,219,.25) 0%,rgba(42,60,87,.4) 100%), linear-gradient(135deg, #670d10 0%,#092756 100%);
}

.App {
    flex: 1; /* Ensure the content takes up available space */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* This aligns the title and form at the top of the screen */
}
